.cui-statusbar .cui-ctl,
.cui-statusbar .cui-ctl-medium {
  display: none !important;
}
.WACStatusBarContainer {
  display: none !important;
}
.custom-doc-viewer .rpv-download {
  display: none !important;
}

.ewa-embed-footer {
  display: none !important;
}
.doc-container {
  margin-top: 2rem;
}

.subscribe-overlay {
  height: 105vh;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999 !important;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 5.87%, rgba(255, 255, 255, 0.30) 100%);
  backdrop-filter: blur(4.5px);
}

.download-button {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.no-select {
  user-select: none;
}

